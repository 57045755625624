import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import Image from '../components/image'
import SEO from '../components/seo'
import './style.css'
// import HeroVid from '../images/williams-hero-vid.mp4'
import HeroVid from '../images/williams_compressed.mp4'
import LogoWhite from '../images/williams-white.png'
import screenGrab from '../images/williams_screengrab.jpg'

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'initial',
      heroArray: [
        {content: "For 2oo years we have been doing it the same way...", id: 1 },
        {content: "Now our robots are able to build your homes.", id: 2 }
      ],
      activeHeroContentId: 1
      
      // Note: think carefully before initializing
      // state based on props!
      // someInitialValue: this.props.initialValue
    }
    this.timer = this.timer.bind(this)
  }


  changeTab(tab) {
    this.setState({
      tab:tab
    })
  }


  componentDidMount() {
    console.log(this.state.heroArray)
    this.cycleHeroContent()
  }

  cycleHeroContent() {
    setInterval(this.timer, 5000);
  }

  timer() {
    console.log(this.state.heroArray.length)
    const activeID = this.state.activeHeroContentId
    if (activeID > this.state.heroArray.length - 1) {
      this.setState({
        activeHeroContentId: 1
      })
    } else {
      this.setState({
        activeHeroContentId: this.state.activeHeroContentId + 1
      })
    }
    console.log(this.state.activeHeroContentId)
  }

  render() {
    let heroContent = this.state.heroArray.map((heroContent) => {
      if (this.state.activeHeroContentId == heroContent.id) {
        return (
          <li key={heroContent.id} className="content-li fadeInHero-2">
            {heroContent.content}
          </li>
        )
      }
    });
    return (
      <div>
      <Header textColorClass = "white-text" logoVersion = {LogoWhite} />
      <SEO title="Williams Robotics" keywords={[`home building`, `construction`, `robots`]} />
      <div className="hero-container">
      
      <video className="hero-video" src={HeroVid} autoPlay loop muted poster={screenGrab}/>
      
      {/* <div className="flash"></div>
      <div className="flash-2"></div>
      <div className="flash-3"></div>
      <div className="robot-fobot"></div> */}
    


      <div className="hero-content section">
        {/* <ul className="hero-content-ul">
          { heroContent }
        </ul> */}
        <ul className="hero-content-ul">
          {/* <li className="">For 2oo years we have been doing it the same way...</li>
          <li className="fadeInHero-2">Now our robots are able to build your homes.</li>
          <li className="fadeInHero-3">Williams Robotics</li> */}
          {heroContent}
        </ul>

      </div>
    </div>
    
    <div className="section-two-wrapper">
      <div className="section-two-content section">
        <p>
          With sticks, with hammers,
          and with billions of dollars a year spent in human resource costs. 
        </p> 
      </div>
    </div>
    
    <div className="section-two-a-wrapper">
      <div className="overlay"></div>
      <div className="section-two-content section">
        <p>
          We are going to build homes with robots. 
        </p> 
        </div>
    </div>

    <div className="section-tabs">
      <h2 className="tab-section-header">How can we help your construction business?</h2>
      <div className="production-cat-wrapper">
        <ul className="tabs">
          <li onClick={this.changeTab.bind(this, 'production') } className={this.state.tab == 'production' ? "active-tab production-tab className_box_shadow": "production-tab className_box_shadow" }>Production</li>
          <li onClick={this.changeTab.bind(this, 'components') } className={this.state.tab == 'components' ? "active-tab production-tab className_box_shadow": "production-tab className_box_shadow" }>Components</li>
          <li onClick={this.changeTab.bind(this, 'multi-family') } className={this.state.tab == 'multi-family' ? "active-tab production-tab className_box_shadow": "production-tab className_box_shadow" }>Multi-Family</li>
          <li onClick={this.changeTab.bind(this, 'framer') } className={this.state.tab == 'framer' ? "active-tab production-tab className_box_shadow": "production-tab className_box_shadow" }>Framer</li>
        </ul>

       { this.state.tab == 'initial' ? <div className="production-cat placeholder">
          <div className="benefits-wrapper">
            <h3>Click your business to find out the benefits.</h3>
            <img className="nav-logo center-logo" src="https://s3-us-west-2.amazonaws.com/williams-robotics/williams-wine.png" />
          </div>
        </div> : null } 

      { this.state.tab == 'production' ? <div className="production-cat production-builder">
          <div className="benefits-wrapper">
            <h4 className="ben-header">Benefits</h4>
            <ul className="benefits">
              <li className="benefit">Save $1500 per home (by labor reduction).</li>
              <li className="benefit">Reduce framing time by 1.5 days.</li>
              <li className="benefit">Increase annual production by 4-5%</li>
              <li className="benefit">Consistent quality.</li>
              <li className="benefit">Reduce material and time waste.</li>
            </ul>
          </div> 
        </div> : null }

         { this.state.tab == 'components' ? <div className="production-cat components">
          <div className="benefits-wrapper">
            <h4 className="ben-header">Benefits</h4>
            <ul className="benefits">
              <li className="benefit">Reduce labor by 2 to 3 people.</li>
              <li className="benefit">One operator produces at 2 wall-feet per minute.</li>
              <li className="benefit">Eliminate rough openings station.</li>
              <li className="benefit">Consistent quality.</li>
              <li className="benefit">No plate marking required.</li>
            </ul>
          </div>
        </div> : null }
        
        { this.state.tab == 'multi-family' ? <div className="production-cat multi-family">
          <div className="benefits-wrapper">
            <h4 className="ben-header">Benefits</h4>
            <ul className="benefits">
              <li className="benefit">Get owner into rent a month sooner.</li>
              <li className="benefit">Reduce crew size by 2 to 3 people.</li>
              <li className="benefit">Frame stud walls up to 2 days faster per floor.</li>
              <li className="benefit">Reduce equipment rental, insurance, and debt service expenses.</li>
              <li className="benefit">Consistent quality.</li>
              <li className="benefit">No plate marking required.</li>
            </ul>
          </div>
        </div> : null }

         { this.state.tab == 'framer' ?  <div className="production-cat framer">
          <div className="benefits-wrapper">
            <h4 className="ben-header">Benefits</h4>
            <ul className="benefits">
              <li className="benefit">Reduce Crew Size by 1 to 2 people.</li>
              <li className="benefit">Frame stud walls for up to three floors in one day..</li>
              <li className="benefit">Consistent quality.</li>
              <li className="benefit">Consistent rate.</li>
              <li className="benefit">No plate marking required.</li>
            </ul>
          </div>
        </div> : null }
        
        
        
        
        
      </div>
    </div>
    
    <div className="section-four-wrapper">
      <div className="section-four-content section">
        <p>
            Williams Robotics is designing and manufacturing robotic systems that will build the homes of the future.  	 
        </p>
        <h3 className="demonstrations">Demonstrations Begin in Spring 2019</h3>
          <Link className="learn-more-link" to="/Products">Learn More</Link>
      </div>
    </div>
    <Footer />
    </div>
    )
  }
 
}

export default IndexPage
